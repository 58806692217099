import styled from 'styled-components';
import { Col } from 'antd';
import { colors } from 'styles/colors';
import { CheckOutlined, CloseOutlined, UpOutlined } from '@ant-design/icons';

const assetsBaseURL = process.env.REACT_APP_AWS_V4_ASSETS;
const shineOutlinedWhite = `${assetsBaseURL}/svg/shineOutlinedWhite.svg`;

export const StCol = styled(Col)`
  padding: 0 32px;
`;

export const PlanComparisonContainer = styled.div`
  max-width: 100%;
  margin: 0px auto 50px;
  padding: 0 24px;
  border-radius: 4px;
  background-color: ${colors.gray1};
  overflow: auto;
`;

export const PlanComparisonTitleContainer = styled.div`
  padding: 40px 0 54px;
  color: ${colors.gray9};
  display: flex;
  align-items: center;

  > img {
    width: 30px;
    height: 36px;
    margin-right: 16px;
  }

  > div {
    display: flex;
    flex-direction: column;

    > strong {
      font-family: 'Roboto';
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      text-align: left;
      color: ${colors.gray9};
    }

    > span {
      font-family: 'Roboto';
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      text-align: left;
      color: ${colors.gray7};
    }
  }
`;

export const PlanComparisonTable = styled.table`
  width: 100%;
  color: ${colors.gray9};
  margin-bottom: 150px;

  thead {
    tr {
      :first-child {
        background-color: ${colors.gray2};
      }
    }
  }

  tbody {
    tr:nth-child(even) {
      background-color: ${colors.gray2};
    }

    .not-available {
      font-family: 'Roboto';
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      color: ${colors.gray6};
    }
  }

  th,
  td {
    width: 10%;
    padding: 16px;
    border: 1px solid ${colors.gray4};
    text-align: center;

    :first-child {
      width: 35%;
      text-align: left;
    }
  }
`;

export const StCustomTh = styled.th`
  ${({ plan }) => {
    if (plan === 'free') {
      return `       
        color: ${colors.primary4};
      `;
    }

    if (plan === 'pro') {
      return `
        color: ${colors.primary6};
      `;
    }

    if (plan === 'controle') {
      return `        
        color: ${colors.green14};
      `;
    }

    if (plan === 'gerencie') {
      return `
        position: relative;
        color: ${colors.cyan11};
      `;
    }

    if (plan === 'plus') {
      return `       
        color: ${colors.gold11};
      `;
    }

    return '';
  }}

  ${({ isMostChosenTagVisible }) =>
    isMostChosenTagVisible &&
    `::after {
          content: url(${shineOutlinedWhite}) ' O mais escolhido';
          position: absolute;
          top: -31px;
          left: 0;
          width: 100%;
          height: 30px;
          background-color: ${colors.cyan11};
          display: flex;
          justify-content: center;
          align-items: center;
          color: ${colors.gray1};
          border-radius: 4px 4px 0 0;
          font-size: 12px;
          font-weight: 500;

          path {
            fill: red !important;
          }
        }`}
`;

export const StCheckOutlined = styled(CheckOutlined)`
  color: ${colors.green14};
  stroke: ${colors.green14};
  stroke-width: 100px;
  font-size: 13px;
`;

export const StCloseOutlined = styled(CloseOutlined)`
  color: ${colors.red11};
  stroke: ${colors.red11};
  stroke-width: 100px;
  font-size: 13px;
`;

export const Footer = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 56px;

  > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;

    > span:first-child {
      color: ${colors.gray9};
      font-family: 'Roboto';
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      text-align: center;
    }

    > span:last-child {
      color: ${colors.gray7};
      font-family: 'Roboto';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
`;

export const BackToTopButton = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;

  > span:first-child {
    color: ${colors.primary6};
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    line-height: 22px;
  }
`;

export const StUpOutlined = styled(UpOutlined)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  line-height: 50px;
  border-radius: 50%;
  background-color: ${colors.background2};
  color: ${colors.gray1};
  text-align: center;
  font-size: 20px;
`;
