import React, { useEffect, useState } from 'react';
import store from 'store';
import ReactCardFlip from 'react-card-flip';
import PlanUpgradeCard from 'components/PlanUpgradeCard/PlanUpgradeCard';
import CurrentPlanCard from 'components/CurrentPlanCard/CurrentPlanCard';
import SalesReconciliationCard from './components/ReconciliationCard/SalesReconciliationCard/SalesReconciliationCard';
import PaymentReconciliationCardBlocked from './components/PaymentReconciliationCardBlocked/PaymentReconciliationCardBlocked';
import { Row, Col, Grid, Skeleton } from 'antd';
import { StContainer, StRow } from './styled';
import { useSelector, useDispatch } from 'react-redux';
import { PaymentsDivergencesCard } from './components/PaymentsDivergencesCard';
import { isPagSeguroApp, isClient } from 'helpers/authorizationService';
import { updateIsImplantedStart } from '../../store/ducks/updateIsImplanted/actions';
import { setReportsCustomColumns } from 'store/ducks/reportsCustomColumns/actions';
import { PaymentsReconciliationCard } from './components/ReconciliationCard/PaymentsReconciliationCard';
import { colors } from 'styles/colors';
import { BankReconciliationCard } from './components/ReconciliationCard/BankReconciliationCard';
import { usePlansFeaturesPermissions } from 'utils/hooks/usePlansFeaturesPermissions';
import { PlanExpireInfoCard } from './components/PlanExpireInfoCard';
import { getSessionFirstName } from 'helpers/sessionService';
import {
  SalesCard,
  AmountsReceivableCard,
  AdjustmentsCard,
  BanksCard,
  DuedateCard,
  AdvancementsCard,
  RefundsCard,
} from 'components/Dashboard/components';
import {
  currentDayMinusOne,
  currentDayPlusOne,
  oneYearPlusOneDay,
  startOfCurrentMonth,
} from 'helpers/dates';
import { AcquirerEdiResponseCard } from './components/AcquirerEdiResponseCard';
import { DemoCard } from './components/DemoCard';
import { UserOnboarding } from './components/UserOnboarding';
import { usePermissions } from 'utils/hooks/usePermissions';

const Dashboard = () => {
  const { isFeatureAvailable } = usePlansFeaturesPermissions();
  const permissionsLoading = useSelector((state) => state.permissions.loading);
  const { fetchPermissions } = usePermissions();
  const { permissions } = store.getState();
  const planId = permissions?.data?.plan_id;
  const skinId = permissions?.data?.skin_id;
  const flgMerchantCreated = permissions?.data?.flg_merchant_created;
  const flgTutorialCompleted = permissions?.data?.flg_tutorial_completed;

  const dispatch = useDispatch();

  const [startDateSales, setStartDateSales] = useState(startOfCurrentMonth);
  const [endDateSales, setEndDateSales] = useState(currentDayMinusOne);

  const [startDateSalesReconciliation, setStartDateSalesReconciliation] =
    useState(startOfCurrentMonth);
  const [endDateSalesReconciliation, setEndDateSalesReconciliation] =
    useState(currentDayMinusOne);

  const [startDatePaymentsReconciliation, setStartDatePaymentsReconciliation] =
    useState(startOfCurrentMonth);
  const [endDatePaymentsReconciliation, setEndDatePaymentsReconciliation] =
    useState(currentDayMinusOne);

  const [startDateBankReconciliation, setStartDateBankReconciliation] =
    useState(startOfCurrentMonth);
  const [endDateBankReconciliation, setEndDateBankReconciliation] =
    useState(currentDayMinusOne);

  const [startDatePaymentsDivergences, setStartDatePaymentsDivergences] =
    useState(startOfCurrentMonth);
  const [endDatePaymentsDivergences, setEndDatePaymentsDivergences] =
    useState(currentDayMinusOne);

  const [startDateAmountsReceivable, setStartDateAmountsReceivable] =
    useState(currentDayPlusOne);
  const [endDateAmountsReceivable, setEndDateAmountsReceivable] =
    useState(oneYearPlusOneDay);

  const [startDateBanks, setStartDateBanks] = useState(startOfCurrentMonth);
  const [endDateBanks, setEndDateBanks] = useState(currentDayMinusOne);

  const [startDateDuedate, setStartDateDuedate] = useState(startOfCurrentMonth);
  const [endDateDuedate, setEndDateDuedate] = useState(currentDayMinusOne);

  const [startDateAdvancements, setStartDateAdvancements] =
    useState(startOfCurrentMonth);
  const [endDateAdvancements, setEndDateAdvancements] =
    useState(currentDayMinusOne);

  const [startDateAdjustments, setStartDateAdjustments] =
    useState(startOfCurrentMonth);
  const [endDateAdjustments, setEndDateAdjustments] =
    useState(currentDayMinusOne);

  const [startDateRefunds, setStartDateRefunds] = useState(startOfCurrentMonth);
  const [endDateRefunds, setEndDateRefunds] = useState(currentDayMinusOne);
  const loadingListOfClients = useSelector(
    (state) => state.listOfClients?.loading
  );

  const { is_implanted: isImplanted } = useSelector(
    (state) => state.listOfClients?.currentClient
  );
  const authLoading = useSelector((state) => state.auth.loading);

  const [isFlipped, setIsFlipped] = useState({
    sales: false,
    bank: false,
  });
  const changeStoreLoading = useSelector(
    (state) => state?.changeStore?.loading
  );
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();

  const salesDateProps = {
    startDateSales,
    endDateSales,
    setStartDateSales,
    setEndDateSales,
    isFlipped,
    setIsFlipped,
  };
  const paymentsDivergencesDateProps = {
    startDatePaymentsDivergences,
    endDatePaymentsDivergences,
    setStartDatePaymentsDivergences,
    setEndDatePaymentsDivergences,
  };
  const amountsReceivableDateProps = {
    startDateAmountsReceivable,
    endDateAmountsReceivable,
    setStartDateAmountsReceivable,
    setEndDateAmountsReceivable,
  };
  const banksDateProps = {
    startDateBanks,
    endDateBanks,
    setStartDateBanks,
    setEndDateBanks,
    isFlipped,
    setIsFlipped,
  };
  const duedateDateProps = {
    startDateDuedate,
    endDateDuedate,
    setStartDateDuedate,
    setEndDateDuedate,
    isDuedate: true,
    isAdvancements: false,
    isAdjustments: false,
    isRefunds: false,
  };
  const advancementsDateProps = {
    startDateAdvancements,
    endDateAdvancements,
    setStartDateAdvancements,
    setEndDateAdvancements,
    isDuedate: false,
    isAdvancements: true,
    isAdjustments: false,
    isRefunds: false,
  };
  const adjustmentsDateProps = {
    startDateAdjustments,
    endDateAdjustments,
    setStartDateAdjustments,
    setEndDateAdjustments,
    isDuedate: false,
    isAdvancements: false,
    isAdjustments: true,
    isRefunds: false,
  };
  const refundsDateProps = {
    startDateRefunds,
    endDateRefunds,
    setStartDateRefunds,
    setEndDateRefunds,
    isDuedate: false,
    isAdvancements: false,
    isAdjustments: false,
    isRefunds: true,
  };

  useEffect(() => {
    dispatch(updateIsImplantedStart(isImplanted));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isImplanted]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setReportsCustomColumns([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StContainer
      $breakpoint={breakpoint}
      disabled={isPagSeguroApp() && isClient()}
    >
      <Skeleton
        loading={changeStoreLoading || permissionsLoading}
        active
        paragraph={{ rows: 16 }}
      >
        <StRow gutter={[16, 16]}>
          <Col xs={0} sm={0} md={2} lg={2} xl={2} xxl={2} />
          <Col xs={24} sm={24} md={20} lg={20} xl={20} xxl={20}>
            {isFeatureAvailable({
              key: 'dashboard-plan-expire-info-card',
            }) && (
              <Col span={24}>
                <Row gutter={[16, 16]} justify="center" align="middle">
                  <PlanExpireInfoCard />
                </Row>
              </Col>
            )}
          </Col>
          <Col xs={0} sm={2} md={2} lg={2} xl={2} xxl={2} />

          <Col xs={0} sm={0} md={2} lg={2} xl={2} xxl={2} />
          <Col xs={24} sm={24} md={20} lg={20} xl={20} xxl={20}>
            <Col span={24}>
              <Row gutter={[16, 16]} justify="center" align="middle">
                <CurrentPlanCard
                  username={getSessionFirstName()}
                  planId={planId}
                  skinId={skinId}
                />
              </Row>
            </Col>
          </Col>
          <Col xs={0} sm={2} md={2} lg={2} xl={2} xxl={2} />

          {isFeatureAvailable({
            key: 'dashboard-acquirerEdiResponseCard',
          }) &&
            flgMerchantCreated &&
            !isImplanted &&
            !loadingListOfClients && (
              <>
                <Col xs={0} sm={0} md={2} lg={2} xl={2} xxl={2} />
                <Col xs={24} sm={24} md={20} lg={20} xl={20} xxl={20}>
                  <Col span={24}>
                    <Row gutter={[16, 16]} justify="center" align="middle">
                      <AcquirerEdiResponseCard />
                    </Row>
                  </Col>
                </Col>
                <Col xs={0} sm={2} md={2} lg={2} xl={2} xxl={2} />
              </>
            )}

          <Col xs={0} sm={0} md={2} lg={2} xl={2} xxl={2} />
          <Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}>
            <Row gutter={[16, 16]} justify="center" align="middle">
              {isFeatureAvailable({
                key: 'demo-card',
              }) &&
                !isImplanted &&
                !authLoading &&
                !loadingListOfClients && (
                  <Col span={24}>
                    <DemoCard />
                  </Col>
                )}
              <Col span={24}>
                {isFeatureAvailable({
                  key: 'dashboard-salesReconciliationCard',
                }) ? (
                  <ReactCardFlip
                    isFlipped={isFlipped?.sales}
                    flipDirection="horizontal"
                  >
                    <SalesCard {...salesDateProps} />
                    <SalesReconciliationCard
                      isFlipped={isFlipped}
                      setIsFlipped={setIsFlipped}
                      dropdownProps={{
                        startDateReconciliation: startDateSalesReconciliation,
                        setStartDateReconciliation:
                          setStartDateSalesReconciliation,
                        endDateReconciliation: endDateSalesReconciliation,
                        setEndDateReconciliation: setEndDateSalesReconciliation,
                      }}
                    />
                  </ReactCardFlip>
                ) : (
                  <SalesCard {...salesDateProps} />
                )}
              </Col>
              <Col span={24}>
                <AmountsReceivableCard {...amountsReceivableDateProps} />
              </Col>
              {isFeatureAvailable({
                key: 'dashboard-paymentsReconciliationCard',
              }) && (
                <Col span={24}>
                  <PaymentsReconciliationCard
                    dropdownProps={{
                      startDateReconciliation: startDatePaymentsReconciliation,
                      setStartDateReconciliation:
                        setStartDatePaymentsReconciliation,
                      endDateReconciliation: endDatePaymentsReconciliation,
                      setEndDateReconciliation:
                        setEndDatePaymentsReconciliation,
                    }}
                  />
                </Col>
              )}

              {isFeatureAvailable({
                key: 'dashboard-paymentsReconciliationCardBlocked',
              }) && (
                <Col span={24}>
                  <PaymentReconciliationCardBlocked />
                </Col>
              )}

              {isFeatureAvailable({
                key: 'dashboard-paymentsDivergencesCard',
              }) && (
                <Col span={24}>
                  <div style={{ backgroundColor: colors.gray1 }}>
                    <PaymentsDivergencesCard
                      {...paymentsDivergencesDateProps}
                    />
                  </div>
                </Col>
              )}
            </Row>
          </Col>
          <Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}>
            <Row gutter={[1, 1]} justify="center" align="middle">
              {isFeatureAvailable({
                key: 'dashboard-planUpgradeCard',
              }) && (
                <Col span={24}>
                  <PlanUpgradeCard />
                </Col>
              )}

              {isFeatureAvailable({
                key: 'dashboard-banksCard',
              }) && (
                <Col span={24}>
                  {isFeatureAvailable({
                    key: 'dashboard-bankReconciliationCard',
                  }) ? (
                    <ReactCardFlip
                      isFlipped={isFlipped?.bank}
                      flipDirection="horizontal"
                    >
                      <BanksCard {...banksDateProps} />
                      <BankReconciliationCard
                        {...banksDateProps}
                        dropdownProps={{
                          startDateReconciliation: startDateBankReconciliation,
                          setStartDateReconciliation:
                            setStartDateBankReconciliation,
                          endDateReconciliation: endDateBankReconciliation,
                          setEndDateReconciliation:
                            setEndDateBankReconciliation,
                        }}
                      />
                    </ReactCardFlip>
                  ) : (
                    <BanksCard {...banksDateProps} />
                  )}
                </Col>
              )}
              <Col span={24}>
                <Row gutter={[1, 1]}>
                  {isFeatureAvailable({
                    key: 'dashboard-duedateCard',
                  }) && (
                    <Col span={12}>
                      <div style={{ backgroundColor: colors.gray1 }}>
                        <DuedateCard {...duedateDateProps} />
                      </div>
                    </Col>
                  )}
                  {isFeatureAvailable({
                    key: 'dashboard-advancementsCard',
                  }) && (
                    <Col span={12}>
                      <div style={{ backgroundColor: colors.gray1 }}>
                        <AdvancementsCard {...advancementsDateProps} />
                      </div>
                    </Col>
                  )}
                  {isFeatureAvailable({
                    key: 'dashboard-adjustmentsCard',
                  }) && (
                    <Col span={12}>
                      <div style={{ backgroundColor: colors.gray1 }}>
                        <AdjustmentsCard {...adjustmentsDateProps} />
                      </div>
                    </Col>
                  )}
                  {isFeatureAvailable({
                    key: 'dashboard-refundsCard',
                  }) && (
                    <Col span={12}>
                      <div style={{ backgroundColor: colors.gray1 }}>
                        <RefundsCard {...refundsDateProps} />
                      </div>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={0} sm={0} md={2} lg={2} xl={2} xxl={2} />
        </StRow>
        {isFeatureAvailable({
          key: 'dashboard-userOnboardingModal',
        }) && (
          <UserOnboarding
            flgTutorialCompleted={flgTutorialCompleted}
            flgMerchantCreated={flgMerchantCreated}
            fetchPermissions={fetchPermissions}
          />
        )}
      </Skeleton>
    </StContainer>
  );
};

export default Dashboard;
