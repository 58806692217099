export const plansFeatures = [
  {
    name: 'Visão das vendas por adquirente',
    isAvailable: true,
    planPermission: [1, 2, 4, 7, 8],
  },
  {
    name: 'Visão das vendas por produto, bandeira e parcela',
    isAvailable: true,
    planPermission: [1, 2, 4, 7, 8],
  },
  {
    name: 'Valores a receber por parcelamento',
    isAvailable: true,
    planPermission: [1, 2, 4, 7, 8],
  },
  {
    name: 'Aging - Visão retroativa dos valores a receber',
    isAvailable: true,
    planPermission: [1, 2, 4],
  },
  {
    name: 'Visão de cancelamentos e chargebacks',
    isAvailable: true,
    planPermission: [1, 2, 4, 8],
  },
  {
    name: 'Controle de aluguel de POS e ajustes',
    isAvailable: true,
    planPermission: [1, 2, 4, 8],
  },
  {
    name: 'Auditoria de taxas e pagamentos',
    isAvailable: true,
    planPermission: [1, 2, 4, 8],
  },
  {
    name: 'Recebimentos por banco, agência e conta',
    isAvailable: true,
    planPermission: [1, 2, 4, 8],
  },
  {
    name: 'Gestão de vendas realizadas por PIX',
    isAvailable: true,
    planPermission: [1, 2, 4, 7, 8],
  },
  {
    name: 'Conciliação de pagamentos',
    isAvailable: true,
    planPermission: [1, 2, 4],
  },
  {
    name: 'Conciliação bancária extrato OFX',
    isAvailable: true,
    planPermission: [2, 4],
  },
  {
    name: 'Conciliação de vendas com integração por API ',
    isAvailable: true,
    planPermission: [4],
  },
  {
    name: 'Ambiente de testes para desenvolvedores',
    isAvailable: true,
    planPermission: [4],
  },
  {
    name: 'Relatórios consolidados em tela',
    isAvailable: true,
    planPermission: [1, 2, 4, 7, 8],
  },
  {
    name: 'Relatórios detalhados exportáveis',
    isAvailable: true,
    planPermission: [1, 2, 4],
  },
  {
    name: 'Calendário ilimitado dentro de 24 meses',
    isAvailable: true,
    planPermission: [1, 2, 4, 8],
  },
  {
    name: 'Radar - Resumo automático por e-mail',
    isAvailable: true,
    planPermission: [1, 2, 4, 8],
  },
  {
    name: 'Convites ilimitados para novos usuários',
    isAvailable: true,
    planPermission: [1, 2, 4, 8],
  },
  {
    name: 'Treinamento automático',
    isAvailable: true,
    planPermission: [1, 2, 4, 7, 8],
  },
  {
    name: 'Treinamento personalizado',
    isAvailable: true,
    planPermission: [4],
  },
  {
    name: 'Gerente de sucesso dedicado',
    isAvailable: true,
    planPermission: [4],
  },
  {
    name: 'Aplicativos nativos Android e IOS',
    isAvailable: true,
    planPermission: [1, 2, 4, 7, 8],
  },
  {
    name: 'Central de ajuda disponível online',
    isAvailable: true,
    planPermission: [1, 2, 4, 7, 8],
  },
  {
    name: 'Suporte por e-mail ou chat área logada',
    isAvailable: true,
    planPermission: [1, 2, 4, 7, 8],
  },
  {
    name: 'Suporte por telefone em dias úteis',
    isAvailable: true,
    planPermission: [1, 2, 4],
  },
];
