import React from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { colors } from 'styles/colors';

export const plansDetails = [
  {
    id: 7,
    name: 'Free',
    description:
      'Ideal para organizar suas vendas sem complicações e sem custo.',
    color: colors.primary4,
    price: 'Grátis',
    hasPriceTag: false,
    hasIcon: true,
    mostChosen: false,
    features: [
      {
        description:
          'Acesso ao essencial para organizar seu fluxo de vendas e recebimentos.',
        icon: <CheckOutlined />,
        bold: false,
      },
      {
        description: 'Informações de vendas e valores a receber.',
        icon: <CheckOutlined />,
        bold: false,
      },
      {
        description:
          'Acesso grátis no app (android e IOS). Faça download e experimente agora.',
        icon: <CheckOutlined />,
        bold: false,
      },
    ],
  },
  {
    id: 6,
    name: 'Pag Grátis',
    description:
      'Plano especial para clientes Pagbank, gratuito por 3 meses. Visão exclusiva dos dados da adquirente PagBank.',
    color: colors.lime5,
    price: 'Grátis por 3 meses',
    hasPriceTag: false,
    hasIcon: true,
    mostChosen: false,
    features: [
      {
        description:
          'Todos os benefícios do plano Controle por tempo limitado.',
        icon: <CheckOutlined />,
        bold: true,
      },
    ],
  },
  {
    id: 8,
    name: 'Pro',
    description:
      'Ideal para iniciantes, com foco em simplicidade e custo-benefício.',
    color: colors.primary6,
    price: 'R$ 39,90/mês',
    hasPriceTag: false,
    hasIcon: false,
    mostChosen: false,
    features: [
      {
        description: 'Todos os recursos do plano free.',
        icon: <CheckOutlined />,
        bold: true,
      },
      {
        description: 'Gestão financeira simples e ágil.',
        icon: <CheckOutlined />,
        bold: false,
      },
      {
        description: 'Cadastro de taxas e auditoria de pagamentos.',
        icon: <CheckOutlined />,
        bold: false,
      },
      {
        description: 'Informações de pagamentos e descontos.',
        icon: <CheckOutlined />,
        bold: false,
      },
      {
        description: 'Radar: resumo semanal e mensal por e-mail.',
        icon: <CheckOutlined />,
        bold: false,
      },
      {
        description: 'Valor fixo por mês.',
        icon: <CheckOutlined />,
        bold: false,
      },
    ],
  },
  {
    id: 1,
    name: 'Controle',
    description:
      'Monitoramento e controle eficiente para pequenas e médias empresas.',
    color: colors.green14,
    price: 'R$ 150/mês',
    hasPriceTag: true,
    hasIcon: false,
    mostChosen: false,
    features: [
      {
        description: 'Todos os benefícios do plano Pro.',
        icon: <CheckOutlined />,
        bold: true,
      },
      {
        description: 'Projeção de contas a receber.',
        icon: <CheckOutlined />,
        bold: false,
      },
      {
        description: 'Conciliação de taxas.',
        icon: <CheckOutlined />,
        bold: false,
      },
      {
        description:
          'Visão detalhada de vendas, valores a receber e recebimentos bancários.',
        icon: <CheckOutlined />,
        bold: false,
      },
      {
        description:
          'Auditoria de taxas e descontos para saber se recebeu corretamente.',
        icon: <CheckOutlined />,
        bold: false,
      },
      {
        description: 'Relatórios exportáveis detalhados.',
        icon: <CheckOutlined />,
        bold: false,
      },
      {
        description: 'Valor de acordo com a quantidade de vendas.',
        icon: <CheckOutlined />,
        bold: false,
      },
    ],
  },
  {
    id: 2,
    name: 'Gerencie',
    description:
      'Otimização da gestão financeira com conciliação bancária automatizada.',
    color: colors.cyan11,
    price: 'R$ 200/mês',
    hasPriceTag: true,
    hasIcon: false,
    mostChosen: true,
    features: [
      {
        description: 'Todos os benefícios do plano Controle.',
        icon: <CheckOutlined />,
        bold: true,
      },
      {
        description: 'Conciliação bancária automatizada.',
        icon: <CheckOutlined />,
        bold: false,
      },
      {
        description: 'Gestão do PIX.',
        icon: <CheckOutlined />,
        bold: false,
      },
      {
        description:
          'Acompanhamento de todas as transações e conciliação precisa dos valores recebidos no banco.',
        icon: <CheckOutlined />,
        bold: false,
      },
      {
        description: 'Valor de acordo com a quantidade de vendas.',
        icon: <CheckOutlined />,
        bold: false,
      },
    ],
  },
  {
    id: 4,
    name: 'Plus',
    description:
      'Conciliação de vendas e integração por API para alto volume de dados.',
    color: colors.gold11,
    price: 'R$ 660/mês',
    hasPriceTag: true,
    hasIcon: false,
    mostChosen: false,
    features: [
      {
        description: 'Todos os benefícios do plano Gerencie.',
        icon: <CheckOutlined />,
        bold: true,
      },
      {
        description: 'Conciliação avançada de vendas.',
        icon: <CheckOutlined />,
        bold: false,
      },
      {
        description: 'Integração API com seu ERP.',
        icon: <CheckOutlined />,
        bold: false,
      },
      {
        description: 'Baixa automática de títulos.',
        icon: <CheckOutlined />,
        bold: false,
      },
      {
        description: 'Acompanhamento personalizado e treinamento exclusivo.',
        icon: <CheckOutlined />,
        bold: false,
      },
      {
        description: 'Valor de acordo com a quantidade de vendas.',
        icon: <CheckOutlined />,
        bold: false,
      },
    ],
  },
];
